import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import clear from '../../assets/clear-24px.svg'
import arrowDown from '../../assets/arr_down_white.svg'
import './input-field-with-options.css'
import {useKeyboardShortcut} from "../../UseKeyboardShortcut";

export default function InputField(
    {
        title,
        type,
        onChanged,
        value,
        options,
        optionProperty,

        placeholder,
        onBlur,
        startWithFocus,
        style,
        className,
        onlySelectableOptions,
        error,
        disabled,
        submit
    }) {
    const [focused, setFocused] = useState(false);
    const [searching, setSearching] = useState('')
    const inputRef = useRef()

    const proxyOnChanged = useCallback((newValue, clicked) => {
        if (onlySelectableOptions) {
            if (clicked) {
                onChanged(newValue)
                setSearching('')
            } else {
                setSearching(newValue)
                onChanged('')
            }
        } else {
            onChanged(newValue)
        }
    }, [onlySelectableOptions, onChanged]);

    const selectableOptions = useMemo(() => {
        if (!options) return [];
        return options
            .filter((o, idx) => o === '' || options.indexOf(o) === idx)
            .filter(o => !onlySelectableOptions || o.toString().toLowerCase().indexOf(searching.toLowerCase()) !== -1)
    }, [options, onlySelectableOptions, searching]);

    const enterClicked = useCallback(() => {
        if (submit) submit()
        if (!focused || !selectableOptions) return;
        if (selectableOptions.length === 1) {
            proxyOnChanged(selectableOptions[0], true);
        }
    }, [proxyOnChanged, submit, focused, selectableOptions]);

    useKeyboardShortcut({
        shortcutKeys: ['Enter'],
        callback: enterClicked,
        focused
    });

    useEffect(() => {
        if (startWithFocus && inputRef.current) {
            inputRef.current.focus()
        }
    }, [startWithFocus, inputRef])

    return <div className={`input-field ${className ? className : ''}`}
                style={style} onClick={e => e.stopPropagation()}>
        <div className={`input-wrapper ${disabled && 'disabled'}`}>
            {(title || error) &&
                <label className='title-label'>{title}{error && <label className='error'> - {error}</label>}</label>}
            <div className='input-field-wrapper'>
                <input type={type}
                       onFocus={_ => setFocused(true)}
                       onBlur={e => {
                           if (onBlur) onBlur()
                           setFocused(false)
                       }}
                       className={(onlySelectableOptions) ? 'non-selectable' : ''}
                       value={(onlySelectableOptions && !value) ? searching : value}
                       checked={value}
                       ref={inputRef}
                       placeholder={placeholder}
                       disabled={disabled}
                       onChange={e => {
                           e.preventDefault();
                           e.stopPropagation();
                           proxyOnChanged(e.target.value, false)
                       }}
                />
                {onlySelectableOptions &&
                    <img className='input-field-icon' src={arrowDown} alt='open'/>}
            </div>
        </div>
        {selectableOptions && focused && <div className='option-wrapper'>
            {selectableOptions
                .map(o => <li key={o} onMouseDown={e => proxyOnChanged(o, true)} className='drop-option'>{o}</li>)}
        </div>}
    </div>
}
