import {useCallback, useMemo} from 'react';
import {doSetTableLocally, thunkSaveDirtyTables} from "../../slice/tableSlice";
import debounce from "lodash.debounce";
import {useDispatch} from "react-redux";

export default function useSetTablePosition() {
    const dispatch = useDispatch();

    const updateTableBackend = useCallback(() => {
        dispatch(thunkSaveDirtyTables)
    }, [dispatch]);

    const updateBackend = useMemo(() => {
        return debounce(updateTableBackend, 800)
    }, [updateTableBackend]);

    const setLocally =  useCallback((id, position) => {
        dispatch(doSetTableLocally({table_id: id, values: {position}}))
    }, [dispatch]);

    const setMultipleLocally =  useCallback((tables) => {
        tables.forEach(t => dispatch(doSetTableLocally({table_id: t.id, values: {position: t.position}})))
    }, [dispatch]);

    return {
        setLocally,
        setMultipleLocally,
        updateBackend
    }
}
