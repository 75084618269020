import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    doFetchEvent,
    selectEventById,
    saveLocallyUpdatedEventToBackend,
    selectLocallyUpdatedEvent,
    updateLocalEvent,
    selectLocallyUpdatedIsDirty, doDeleteEvent,
} from "../../../slice/eventSlice";
import {doGetUser} from "../../../slice/userSlice";
import {
    doFetchEventRoom,
    doFetchEventRooms,
    doFetchVenueRooms,
    selectEventRooms,
    selectVenueRooms
} from "../../../slice/roomSlice";
import './event-settings.css';
import InputField from "../../../component/input-field/InputField";
import {toDanishDate} from "../../../component/date_functions";
import RoomList from "../../../component/room-list/RoomList";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import {doDeleteAllEventGuests} from "../../../slice/guestSlice";
import CenteredPopup from "../../../component/centered-popup/CenteredPopup";
import {EventApi} from "../../../api";

export default function EventSettings() {
    const {event_id, venue_id} = useParams()

    const currentEvent = useSelector(selectEventById(event_id))
    const updatedEvent = useSelector(selectLocallyUpdatedEvent)
    const updatedEventIsDirty = useSelector(selectLocallyUpdatedIsDirty)
    const rooms = useSelector(selectEventRooms(event_id));

    const venueRooms = useSelector(selectVenueRooms(venue_id));

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [addNewRoomPopupOpen, setAddNewRoomPopupOpen] = useState(false);
    const [selectedNewRoom, setSelectedNewRoom] = useState(null);
    const [addNewRoomLoading, setAddNewRoomLoading] = useState(false);

    useEffect(() => {
        dispatch(doGetUser())
        dispatch(doFetchEvent(event_id));
        dispatch(doFetchEventRooms(event_id));
        dispatch(doFetchVenueRooms(venue_id));
    }, [dispatch, event_id]);

    const editLocalEvent = useCallback((key, newValue) => {
        dispatch(updateLocalEvent({event_id: currentEvent.id, values: {[key]: newValue}}))
    }, []);

    if (!currentEvent || !updatedEvent) {
        return <div>Loading...</div>
    }

    return (<div className={'event-settings'}>
        <CenteredPopup showPopup={addNewRoomPopupOpen} closePopup={() => setAddNewRoomPopupOpen(false)}>
            <h2>Tilføj nyt lokale</h2>
            {addNewRoomLoading && <>
                <p>Loading...</p>
                <p>
                    Det kan tage et stykke tid fra du trykker 'tilføj' til det nye lokale er oprettet.
                    Hvis det ligner det ikke virker - så giv den 30 sekunder og så refresh siden.
                </p>
            </>}
            {!addNewRoomLoading && venueRooms && <>
                <InputField
                    options={venueRooms.map(r => r.name)}
                    onlySelectableOptions
                    title='Lokale'
                    value={selectedNewRoom || ''}
                    onChanged={v => setSelectedNewRoom(v)}
                />
                <SimpleButton value='Tilføj' disabled={!selectedNewRoom} onClick={_ => {
                    const ev = venueRooms.find(vr => vr.name === selectedNewRoom)
                    setSelectedNewRoom(null);
                    setAddNewRoomLoading(true);
                    EventApi.add_room_to_event(event_id, ev.id).then(_ => {
                        dispatch(doFetchEventRooms(event_id))
                        setAddNewRoomPopupOpen(false);
                        setAddNewRoomLoading(false);
                    });
                }}/>
            </>}
        </CenteredPopup>
        <h2>Rediger event</h2>
        <div className='event-input-fields'>
            <InputField title='Title' value={updatedEvent.title ?? ''} onChanged={v => editLocalEvent('title', v)}/>
            <InputField title='Dato' type='date' value={toDanishDate(updatedEvent.date ?? '')}
                        onChanged={v => editLocalEvent('date', v)}/>

            <InputField title='Fra' type='time' value={updatedEvent.timestamp_from ?? ''}
                        onChanged={v => editLocalEvent('timestamp_from', v)}/>
            <InputField title='Til' type='time' value={updatedEvent.timestamp_to ?? ''}
                        onChanged={v => editLocalEvent('timestamp_to', v)}/>

            <InputField title='Kontakt navn' value={updatedEvent.contact_name ?? ''}
                        onChanged={v => editLocalEvent('contact_name', v)}/>
            <InputField title='Kontakt email' value={updatedEvent.contact_email ?? ''}
                        onChanged={v => editLocalEvent('contact_email', v)}/>
            <InputField title='Kontakt tlf' value={updatedEvent.contact_phone ?? ''}
                        onChanged={v => editLocalEvent('contact_phone', v)}/>
            <InputField title='Antal gæster' value={updatedEvent.expected_guests ?? ''}
                        onChanged={v => editLocalEvent('expected_guests', v)}/>

            <SimpleButton value='GEM'
                          onClick={_ => dispatch(saveLocallyUpdatedEventToBackend)}
                          disabled={!updatedEventIsDirty}
            />
        </div>
        {rooms && <div className='rooms'>
            <h2>Lokaler</h2>
            <RoomList event_id={event_id} venue_id={venue_id} rooms={rooms} show_delete_btn/>
            <SimpleButton value='Tilføj lokale' onClick={_ => {
                setAddNewRoomPopupOpen(true)
            }}/>
        </div>}

        <hr/>

        <div className='danger-zone'>
            <h1>Danger zone</h1>
            <b>
                Pas på hernede. De følgende knapper kan bruges til at slette hele events eller alle gæster på eventet.
                Det kan ikke fortrydes.
            </b>

            <SimpleButton dangerous value='Slet event' onClick={_ => {
                if (window.confirm('Er du sikker på du vil slette event, seating, bordopsætning og gæster?')) {
                    dispatch(doDeleteEvent(parseInt(event_id)))
                    navigate(`/app/venue/${venue_id}/events`)
                }
            }}/>

            {currentEvent && <SimpleButton dangerous value='Slet alle gæster på event' onClick={_ => {
                if (!window.confirm(`Slet alle gæster for ${currentEvent.title}?`)) return;
                dispatch(doDeleteAllEventGuests(currentEvent.id))
            }}/>}
        </div>
    </div>)
}
