import React, {useEffect} from "react";
import {doFetchRoomTables, doFetchTableTemplates, selectAllTables} from "../../slice/tableSlice";
import RoomTable from "../../component/room-table/RoomTable";
import SquareWithText from "../../component/room/element/representation/square-with-text/SquareWithText";
import DistanceLine from "../../component/room/element/representation/distance-line/DistanceLine";
import Oval from "../../component/room/element/representation/oval/Oval";
import Room from "../../component/room/Room";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {doFetchRoom, doSetActiveRoomId, selectActiveRoom, selectRoomById} from "../../slice/roomSlice";
import {doListRoomElements} from "../../slice/elementSlice";

export default function ExportEventPage() {
    const {room_id} = useParams();

    const tables = useSelector(selectAllTables);
    const elements = useSelector(state => state.element.elements)
    const room = useSelector(selectRoomById(parseInt(room_id)));

    const dispatch = useDispatch();
    useEffect(() => {
        console.log('ACTIVE ROOM ID set to', room_id)
        dispatch(doSetActiveRoomId(room_id))
        dispatch(doFetchRoom(room_id))
    }, [room_id]);


    useEffect(() => {
        if (room === null) return;
        dispatch(doFetchRoomTables(room.id))
        dispatch(doListRoomElements(room.id))
        dispatch(doFetchTableTemplates({room_id: room.id, venue_id: null}))
    }, [dispatch, room]);

    if (!room) return null;

    return (<Room room_id={room_id}>
        {tables.map(t => <RoomTable key={t.id}
                                    table={t}
                                    room={room}/>)}

        {Object.values(elements).map(e => {
            if (e.type === 'square') {
                return <SquareWithText key={e.id} element_id={e.id} editable/>
            }
            if (e.type === 'line') {
                return <DistanceLine key={e.id} element_id={e.id} editable/>
            }
            if (e.type === 'oval') {
                return <Oval key={e.id} element_id={e.id} editable/>
            }
        })}
    </Room>)
}
