import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    doGetUser,
    selectSelectedEvent,
} from "../../../slice/userSlice";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import {Link, useNavigate} from "react-router-dom";
import './venue-client-seating-page.css'
import {doFetchEventGuests, selectGuests} from "../../../slice/guestSlice";
import {doFetchEvent, doListUsersByEvent} from "../../../slice/eventSlice";
import {doFetchEventRoom, doFetchEventRooms, selectEventRooms} from "../../../slice/roomSlice";
import {EventApi} from "../../../api";

export default function VenueClientSeatingPage() {
    const [seatingOverview, setSeatingOverview] = useState(null);

    const selectedEvent = useSelector(selectSelectedEvent);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const gotoSeating = useCallback(() => {
        navigate(`/app/event/${selectedEvent.id}/seating`)
    }, [navigate, selectedEvent])

    const guests = useSelector(selectGuests)
    const guestCount = useMemo(() => guests.reduce((prev, curr) => prev + curr.count, 0), [guests])

    const availableSeats = useMemo(() => {
        if (!seatingOverview) return 0;
        return seatingOverview.reduce((prev, curr) => prev + curr.active_seats, 0)
    }, [seatingOverview]);

    const eventRooms = useSelector(selectEventRooms(selectedEvent ? selectedEvent.id : null));

    useEffect(() => {
        if (!selectedEvent) return;
        dispatch(doGetUser())
        dispatch(doFetchEvent(selectedEvent.id));
        dispatch(doFetchEventRooms(selectedEvent.id));
        dispatch(doFetchEventGuests(selectedEvent.id))

        EventApi.list_seating_overview(selectedEvent.id).then(setSeatingOverview);
    }, [dispatch, selectedEvent]);

    if (!selectedEvent) return 'Loading...'

    return (<div className='venue-client-seating-page'>
        <div className='seating-overview'>
            <h3>Seating status</h3>
            <table>
                <tbody>
                <tr>
                    <th>Forventet antal gæster</th>
                    <td>{selectedEvent.expected_guests}</td>
                </tr>
                <tr>
                    <th>Gæster importeret af kunde:</th>
                    <td>{guestCount}</td>
                </tr>
                <tr>
                    <th>Antal med nuværende setup:</th>
                    <td>{availableSeats}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <h2>Før du går i gang med seating, bør du læse disse tips:</h2>

        <div className='tip-wrapper'>
            <div className='tip'>
                <h3>Sørg for, at dine borde ikke er helt fyldte</h3>
                <p>Det giver dine gæster en bedre oplevelse, hvis de har lidt mere plads
                    til deres albuer.</p>
            </div>
            <div className='tip'>
                <h3>Fyld fra de nederste etager og op.</h3>
                <p>Alle pladser er gode - men der
                    er stadig nogle pladser, der har
                    en bedre udsigt end andre.</p>
            </div>
            <div className='tip'>
                <h3>Rød, Gul, Grøn</h3>
                <ul>
                    <li>Rød - bordet er fyldt</li>
                    <li>Gul - nogle sidder</li>
                    <li>Grøn - tom</li>
                </ul>
            </div>
        </div>

        <h2>Så er vi klar - lad os komme i gang!</h2>

        {selectedEvent && !!selectedEvent.locked_seating &&
            <h3 style={{color: 'red', fontWeight: 'bold'}}>Seating er låst for denne begivenhed - kontakt os venligst,
                hvis du
                mener, at dette er en fejl.</h3>}
        {eventRooms && eventRooms.map(event_room =>
            <SimpleButton className='goto-seating-btn' key={event_room.id} value={`Seating til ${event_room.name}`} onClick={_ => {
                navigate(`/app/event/${selectedEvent.id}/room/${event_room.id}/seating`)
            }}/>
        )}

        <div className='grower'/>
        {selectedEvent && <div className='contact-wrapper'>
            <div className='vertical'>
                <h3>{selectedEvent.contact_name}</h3>
                <h3>{selectedEvent.contact_email}</h3>
                <h3>{selectedEvent.contact_phone}</h3>
            </div>
        </div>}
    </div>)
}
