import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import './create-new-event.css'
import {selectVenueById, selectVenueLoading} from "../../slice/venueSlice";
import {doCreateEvent, doFetchVenueEvents, selectCreatingEvent, selectVenueEvents} from "../../slice/eventSlice";
import {doFetchTableTemplates} from "../../slice/tableSlice";
import InputField from "../input-field/InputField";
import SimpleButton from "../simple-button/SimpleButton";
import checkmark from '../../assets/checkmark.svg';
import {doFetchVenueRooms, selectVenueRooms} from "../../slice/roomSlice";

export default function CreateNewEvent({callback}) {
    const dispatch = useDispatch()

    const {venue_id: _vanue_id} = useParams()
    const venue_id = useMemo(() => parseInt(_vanue_id), [_vanue_id])

    const venue = useSelector(selectVenueById(venue_id));
    const events = useSelector(selectVenueEvents(venue_id));
    const rooms = useSelector(selectVenueRooms(venue_id));
    const creatingEvent = useSelector(selectCreatingEvent);
    const venueLoading = useSelector(selectVenueLoading);

    useEffect(() => {
        if (!venue_id) return;
        dispatch(doFetchVenueRooms(venue_id));
        dispatch(doFetchTableTemplates({venue_id, room_id: null}));
        dispatch(doFetchVenueEvents(venue_id))
    }, [dispatch, venue_id])

    // new event creation
    const [eventTitle, setEventTitle] = useState('')
    const [contactName, setContactName] = useState('')
    const [contactPhone, setContactPhone] = useState('')
    const [contactEmail, setContactEmail] = useState('')
    const [eventDate, setEventDate] = useState('')
    const [timestampFrom, setTimestampFrom] = useState('')
    const [timestampTo, setTimestampTo] = useState('')
    const [expectedGuests, setExpectedGuests] = useState('')
    const [eventRooms, setEventRooms] = useState([]);
    const [localEventLoading, setLocalEventLoading] = useState(false);

    // Tab
    const [selectedTab, setSelectedTab] = useState('Events')

    useEffect(() => {
        if (localEventLoading && !creatingEvent) {
            setLocalEventLoading(false);

            setEventTitle('')
            setContactName('')
            setContactPhone('')
            setContactEmail('')
            setEventDate('')
            setTimestampFrom('')
            setTimestampTo('')
            setExpectedGuests('')
            setEventRooms([])
            if (callback !== null) callback(false)
        }
    }, [localEventLoading, creatingEvent, callback])

    const createEvent = useCallback(() => {
        setLocalEventLoading(true)
        dispatch(doCreateEvent({
            venue_id,
            title: eventTitle,
            contact_name: contactName,
            contact_phone: contactPhone,
            contact_email: contactEmail,
            date: eventDate,
            timestamp_from: timestampFrom.length > 0 ? timestampFrom : null,
            timestamp_to: timestampTo.length > 0 ? timestampTo : null,
            expected_guests: expectedGuests,
            room_ids: eventRooms
        }))
    }, [dispatch, venue_id, eventTitle, contactName, contactPhone, contactEmail, eventDate, timestampFrom, timestampTo, expectedGuests, eventRooms]);

    const eventCreatable = useMemo(() => {
        return eventTitle.length > 0 && eventDate.length > 0 && expectedGuests.length > 0 && eventRooms.length > 0
    }, [eventTitle, eventDate, timestampFrom, timestampTo, expectedGuests, eventRooms]);

    console.log(timestampFrom, timestampTo)

    if (!venue) return <p>Loading..</p>

    return (<div className={'create-new-event'}>
        <h1>Nyt Event</h1>
        <InputField value={eventTitle} title={'Navngiv event'} onChanged={setEventTitle}/>

        <div className='date-time-selection-wrapper'>
            <InputField value={eventDate} type='date' title='Dato' onChanged={setEventDate}/>
            <span>Fra</span>
            <InputField type="time" value={timestampFrom} onChanged={setTimestampFrom}/>
            <span>Til</span>
            <InputField type="time" value={timestampTo} onChanged={setTimestampTo}/>
        </div>

        <div className='room-guestcount-wrapper'>
            <div>
                <h4>Lokaler</h4>
                <div className='room-selection'>
                    {rooms.map(room => <div className={`room-it ${eventRooms.indexOf(room.id) !== -1 && 'selected'}`}
                                               key={room.id}
                                               onClick={_ => setEventRooms(prev => (prev.indexOf(room.id) !== -1) ? prev.filter(e => e !== room.id) : [...prev, room.id])}>
                        <span>{room.name}</span>
                        <img src={checkmark} alt='checkmark' />
                    </div>)}
                </div>
            </div>
            <InputField value={expectedGuests} title={'Forventet antal gæster'} onChanged={setExpectedGuests}/>
        </div>

        <div className='grower'>&nbsp;</div>

        <div className='submit-btn-wrapper'>
            <SimpleButton value='Annuller' onClick={_ => callback(false)}/>
            <SimpleButton disabled={!eventCreatable}
                          value='Opret event'
                          onClick={createEvent}
                          loading={creatingEvent}/>
        </div>
    </div>)
}
