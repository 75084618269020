import React from 'react';
import {useSelector} from "react-redux";
import './element-context-menu.css';
import {selectElementContextMenu} from "../../../../slice/elementSlice";
import useElements from "../useElements";

export default function ElementEditMenu() {
    const elements = useSelector(selectElementContextMenu);

    const {renderElementContextMenu} = useElements();

    if (elements === null) return null;

    return (<div className='element-context-menu'>
        {renderElementContextMenu(elements)}
    </div>)
}
