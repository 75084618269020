import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    doSetSelectedTablesAndElements, selectSelectedTableIds,
} from "../../slice/tableSlice";
import Dragable from "../dragable-handle/Dragable";
import './room-table.css'
import useSetTablePosition from "../room/useSetTablePosition";
import {doSetContextMenu} from "../../slice/elementSlice";

export default function RoomTable({
                                      table,
                                      room,
                                      custRef = null,
                                      pointer_events = true,
                                      ...props
                                  }) {
    const dispatch = useDispatch()
    const {setLocally, updateBackend} = useSetTablePosition()
    const context_table_ids = useSelector(selectSelectedTableIds)

    return (<Dragable
            onContextMenu={e => {
                e.preventDefault();
                e.stopPropagation();

                dispatch(doSetSelectedTablesAndElements({table_ids: [table.id], element_ids: []}))
                dispatch(doSetContextMenu({
                    table_ids: [table.id],
                    element_ids: []
                }))
            }}
            onClick={e => dispatch(doSetSelectedTablesAndElements({table_ids: [table.id], element_ids: []}))}
            onAltDrag={e => {
                console.log('alt drag', JSON.stringify([table]))
                e.dataTransfer.setData('application/tableDragCreate', JSON.stringify([table]))
            }}
            element_id={table.id}
            is_table={true}
            key={table.id}
            className={`table ${table.type}`}
            position={table.position}
            rotation={table.rotation}
            style={{
                width: `calc(${table.width * room.pixel_pr_meter}px - 0px)`,
                height: `calc(${table.length * room.pixel_pr_meter}px - 0px)`,
                transform: `translateX(-50%) translateY(-50%) rotate(${table.rotation}deg)`,
                zIndex: context_table_ids && context_table_ids.some(t => t === table.id) ? 4 : 1,
                fontWeight: context_table_ids && context_table_ids.some(t => t === table.id) ? 'bold' : 'normal',
                background: table.surface_color,
            }}
            is_active
            pointer_events={pointer_events}
            setPosition={p => {
                setLocally(table.id, {x: p.x, y: p.y})
                updateBackend()
            }}
            {...props}
        >
            {table.type === 'Square' && <>
                <div className='seats left' style={{
                    left: -table.seat_size * room.pixel_pr_meter + 'px',
                }}>
                    {new Array(Math.floor(table.max_seating / 2)).fill(0).map((_, i) => <div key={`left-${i}`}
                                                                                             className='chair' style={{
                        width: `${table.seat_size * room.pixel_pr_meter - 2}px`,
                        height: `${table.seat_size * room.pixel_pr_meter - 2}px`,
                        background: table.seat_color,
                    }}>
                    </div>)}
                </div>
                <div className='seats right' style={{
                    left: (table.width * room.pixel_pr_meter + 2) + 'px',
                }}>
                    {new Array(Math.floor(table.max_seating / 2)).fill(0).map((_, i) => <div key={`right-${i}`}
                                                                                             className='chair' style={{
                        width: `${table.seat_size * room.pixel_pr_meter - 2}px`,
                        height: `${table.seat_size * room.pixel_pr_meter - 2}px`,
                        background: table.seat_color
                    }}>
                    </div>)}
                </div>
                <div className='seating-label-wrapper' ref={custRef} style={{
                    transform: `translateX(-50%) translateY(-50%) rotate(${-table.rotation}deg)`,
                    width: 'auto',
                    height: 'auto',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    color: table.text_color,
                }}>
                <span style={{color: table.text_color}}>
                    {table.name}
                </span>
                </div>
            </>}
            {table.type === 'Round' && <>
                {new Array(table.max_seating).fill(0).map((_, i) => <div key={`round-${i}`} className='chair' style={{
                    position: 'absolute',
                    width: `${(table.seat_size) * room.pixel_pr_meter}px`,
                    height: `${(table.seat_size) * room.pixel_pr_meter}px`,
                    left: `${0.5 + ((table.width - table.seat_size) / 2 * room.pixel_pr_meter) + Math.floor(Math.cos(i / table.max_seating * 2 * Math.PI) * ((table.width + table.seat_size + 0.1) * room.pixel_pr_meter / 2))}px`,
                    top: `${0.5 + ((table.width - table.seat_size) / 2 * room.pixel_pr_meter) + Math.floor(Math.sin(i / table.max_seating * 2 * Math.PI) * ((table.width + table.seat_size + 0.1) * room.pixel_pr_meter / 2))}px`,
                    background: table.seat_color,
                }}>
                </div>)}
                <div className='seating-label-wrapper' ref={custRef} style={{
                    transform: `translateX(-50%) translateY(-50%) rotate(${-table.rotation}deg)`,
                    width: 'auto',
                    height: 'auto',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    color: table.text_color,
                }}>
                <span style={{color: table.text_color}}>
                    {table.name}
                </span>
                </div>
            </>}
            {table.type === 'Cinema' && <>
                <div className='seats' style={{
                    left: '2px',
                }}>
                    {new Array(table.max_seating).fill(0).map((_, i) => <div key={`right-${i}`}
                                                                             className='chair' style={{
                        width: `${table.seat_size * room.pixel_pr_meter - 2}px`,
                        height: `${table.seat_size * room.pixel_pr_meter - 2}px`,
                        background: table.seat_color,
                        color: table.text_color,
                    }}>
                    </div>)}
                </div>
                <div className='seating-label-wrapper' ref={custRef} style={{
                    transform: `translateX(0) translateY(-50%) rotate(${-table.rotation}deg)`,
                    width: '100%',
                    height: 'auto',
                    position: 'absolute',
                    top: '50%',
                    right: '0',
                    color: table.text_color,
                }}>
                <span style={{color: table.text_color}}>
                    {table.name}
                </span>
                </div>
            </>}
        </Dragable>
    )
}
