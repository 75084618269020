import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectZoom} from "../../slice/roomSlice";
import {doSetSelectedTablesAndElements, selectAllTables, selectDraggingTableId} from "../../slice/tableSlice";
import {elementTypes} from "../room/element/useElements";
import MouseEventHandler from "../mouse-event-handler/MouseEventHandler";

export function DragWindowHelper({containerRef}) {
    const [mouseStart, setMouseStart] = useState({x: 0, y: 0});
    const [mousePos, setMousePos] = useState({x: 0, y: 0});
    const [isMouseDown, setIsMouseDown] = useState(false);

    const [rectPosition, setRectPosition] = useState({
        topLeft: {x: 0, y: 0},
        botRight: {x: 0, y: 0},
        width: 0,
        height: 0
    });

    const dispatch = useDispatch()

    const zoom = useSelector(selectZoom)
    const draggingTableId = useSelector(selectDraggingTableId);

    const allTables = useSelector(state => state.table.tables);
    const elements = useSelector(state => state.element.elements);

    // Handle selected tables
    useEffect(() => {
        if (!rectPosition) return;
        if (draggingTableId !== null) {
            if (draggingTableId !== 'multiple') dispatch(doSetSelectedTablesAndElements({
                table_ids: [],
                element_ids: []
            }))
            return;
        }
        if (!isMouseDown) return;
        const topLeft = rectPosition.topLeft;
        const botRight = rectPosition.botRight;
        const tables = Object.values(allTables).filter(t => {
            // checks if any of the corners of the table is inside the drag window
            const p = t.position;
            const w = t.width;
            const l = t.length;
            return (
                (p.x > topLeft.x && p.x < botRight.x && p.y > topLeft.y && p.y < botRight.y) ||
                (p.x + w > topLeft.x && p.x + w < botRight.x && p.y > topLeft.y && p.y < botRight.y) ||
                (p.x > topLeft.x && p.x < botRight.x && p.y + l > topLeft.y && p.y + l < botRight.y) ||
                (p.x + w > topLeft.x && p.x + w < botRight.x && p.y + l > topLeft.y && p.y + l < botRight.y)
            )
        })
        const selectedElements = Object.values(elements).filter(e => {
            if (!elementTypes[e.type].isWithinBounds) return false;
            const withinBoundsFunc = elementTypes[e.type].isWithinBounds;
            return withinBoundsFunc(topLeft, botRight, e)
        })
        const selectedTableIds = tables.map(t => t.id)
        const selectedElementIds = selectedElements.map(e => e.id)

        dispatch(doSetSelectedTablesAndElements({table_ids: selectedTableIds, element_ids: selectedElementIds}))
    }, [dispatch, allTables, elements, draggingTableId, rectPosition, isMouseDown]);

    useEffect(() => {
        if (!containerRef.current || !isMouseDown) {
            setRectPosition({topLeft: {x: 0, y: 0}, botRight: {x: 0, y: 0}, width: 0, height: 0, isMouseDown: false})
            return;
        }

        // Since we are using a div, we need the min and max of x and y
        const topLeft = {x: Math.min(mouseStart.x, mousePos.x), y: Math.min(mouseStart.y, mousePos.y)};
        const botRight = {x: Math.max(mouseStart.x, mousePos.x), y: Math.max(mouseStart.y, mousePos.y)};

        const width = (botRight.x - topLeft.x);
        const height = (botRight.y - topLeft.y);

        setRectPosition({topLeft, botRight, width, height, isMouseDown})
    }, [containerRef.current, mouseStart, mousePos, isMouseDown]);

    useEffect(() => {
        if (!containerRef || !containerRef.current) return;

        function mouseDown(e) {
            if (e.buttons !== 1) return;
            if (e.target !== containerRef.current) return;
            setMouseStart({x: e.offsetX, y: e.offsetY});
            setMousePos({x: e.offsetX, y: e.offsetY});
            setIsMouseDown(true);
        }

        function mouseMove(e) {
            if (e.buttons !== 1) return;
            if (e.target !== containerRef.current) {
                setMousePos(p => ({x: p.x + e.movementX / zoom, y: p.y + e.movementY / zoom}))
                return;
            }
            setMousePos({x: e.offsetX, y: e.offsetY})
        }

        function mouseUp(e) {
            setIsMouseDown(false);
        }

        containerRef.current.addEventListener('mousedown', mouseDown);
        containerRef.current.addEventListener('mousemove', mouseMove);
        containerRef.current.addEventListener('mouseup', mouseUp);
        return () => {
            if (!containerRef.current) return;
            containerRef.current.removeEventListener('mousedown', mouseDown);
            containerRef.current.removeEventListener('mousemove', mouseMove);
            containerRef.current.removeEventListener('mouseup', mouseUp);
        }
    }, [containerRef.current, zoom]);

    if (draggingTableId !== null) return null;

    return <>
        <div style={{
            position: 'absolute',
            left: `${rectPosition.topLeft.x}px`,
            top: `${rectPosition.topLeft.y}px`,
            width: `${rectPosition.width}px`,
            height: `${rectPosition.height}px`,
            background: '#339af033',
            border: '1px solid #339af0',
            zIndex: 100,
            pointerEvents: 'none'
        }}>
            &nbsp;
        </div>
        {isMouseDown && <MouseEventHandler killAllOtherEvents
                                           zIndex={150}
                                           mouseUp={_ => setIsMouseDown(false)}
        />}
    </>
}
