import React, {useState} from 'react';
import TableModel from "../../../page/room/table-model/TableModel";
import './table-template-menu.css';
import {useDispatch, useSelector} from "react-redux";
import {doDeleteTable, selectVenueTableTemplates} from "../../../slice/tableSlice";
import CreateNewTableTemplate from "../../create-table-template/CreateNewTableTemplate";
import CenteredPopup from "../../centered-popup/CenteredPopup";
import {selectActiveRoom} from "../../../slice/roomSlice";
import BottomPagePopoutMenu from "../../bottom-page-popout/BottomPagePopout";
import RoomTable from "../../room-table/RoomTable";
import editIcon from '../../../assets/edit.png';
import check from '../../../assets/checkmark.svg';
import trash from '../../../assets/bin.png';
import SimpleButton from "../../simple-button/SimpleButton";

export default function TableTemplateMenu() {
    const [creatingNewTable, setCreatingNewTable] = useState(false);
    const [editing, setEditing] = useState(false);

    const room = useSelector(selectActiveRoom);

    const dispatch = useDispatch();

    const venueTemplates = useSelector(selectVenueTableTemplates);

    return (<>
            <CenteredPopup showPopup={creatingNewTable} closePopup={setCreatingNewTable}>
                <CreateNewTableTemplate room_id={room.id}
                                        venue_id={room.venue_id}
                                        isVenueTemplate={false}
                                        onClose={_ => setCreatingNewTable(false)}/>
            </CenteredPopup>
            <div className='table-template-header'>
                <h2>Borde</h2>
                {!editing && <img src={editIcon} alt='edit' onClick={_ => setEditing(true)}/>}
                {editing && <SimpleButton value='færdig' onClick={_ => setEditing(false)}/>}
            </div>
            <div className='table-template-menu'>
                <div className='model-list'>
                    {venueTemplates.map(t => <div key={t.id}
                                                  draggable={!editing}
                                                  style={{
                                                      cursor: !editing ? 'grab' : 'default',
                                                  }}
                                                  className='table-3d-model-wrapper'
                                                  onDragStart={e => {
                                                      e.dataTransfer.setData('application/tableDragCreate', JSON.stringify([{
                                                          ...t,
                                                          venue_id: null,
                                                          is_venue_template: false
                                                      }]))
                                                  }}>
                        <div style={{
                            position: 'relative',
                            marginLeft: '40px',
                            width: '50px',
                            transform: 'scale(0.5) translateY(50%)'
                        }}>
                            <RoomTable table={{...t, position: {x: 0, y: 0}, rotation: 0, name: ''}}
                                       pointer_events={false}
                                       draggable={false}
                                       key={`table_preview-${t.id}`}
                                       room={{
                                           ...room,
                                           pixel_pr_meter: 100 / (t.length + (t.type === 'Round' ? t.seat_size * 2 : 0)),
                                       }}
                            />
                        </div>
                        <div className='table-info'>
                            <h3>{t.name}</h3>
                            <p>({t.width}, {t.length})m dimensions</p>
                            <p>{t.max_seating} max</p>
                        </div>
                        {editing && <div className='table-edit'>
                            <img src={trash} alt='delete' onClick={_ => {
                                if (window.confirm('Er du sikker på at du vil slette dette bord? Du sletter kun din template - ikke bordene i rummet')) {
                                    dispatch(doDeleteTable(t.id))
                                }
                            }}/>
                        </div>}
                    </div>)}
                </div>
                <div className='create-new-table-background'>
                    <p onClick={_ => setCreatingNewTable(true)} className='create-new-table-pop-btn'>+ opret nyt
                        bord</p>
                </div>
            </div>
        </>
    )

}