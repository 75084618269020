import React, {useCallback, useState} from 'react'

import './create-new-table-template.css';
import {doCreateTable, selectAllTables} from "../../slice/tableSlice";
import InputField from "../input-field/InputField";
import SimpleButton from "../simple-button/SimpleButton";
import {useDispatch, useSelector} from "react-redux";
import TableColorPickers from "../table-color-pickers/TableColorPickers";

export default function CreateNewTableTemplate({room_id, venue_id, onClose}) {
    const [newTableName, setNewTableName] = useState('');
    const [newTableMax, setNewTableMax] = useState(8);
    const [newTableType, setNewTableType] = useState('Square');

    const [newTableLength, setNewTableLength] = useState(200);
    const [newTableWidth, setNewTableWidth] = useState(80);
    const [newTableRadius, setNewTableRadius] = useState(120);
    const [newTableHeight, setNewTableHeight] = useState(80);

    // cinema specific
    const [seatWidth, setSeatWidth] = useState(50);
    // end cinema specific

    const [surfaceColor, setSurfaceColor] = useState('#333333');
    const [textColor, setTextColor] = useState('white');
    const [seatColor, setSeatColor] = useState('#0059D4');

    const dispatch = useDispatch()

    const onCreateTable = useCallback(() => {
        let length = parseFloat(newTableLength) / 100
        let width = parseFloat(newTableWidth) / 100
        if (newTableType === 'Round') {
            length = parseFloat(newTableRadius) / 100
            width = parseFloat(newTableRadius) / 100
        } else if (newTableType === 'Cinema') {
            length = parseFloat(seatWidth)/ 100 * newTableMax
            width = 0.8
        }
        dispatch(doCreateTable({
            venue_id,
            room_id,
            name: newTableName,
            max_seating: newTableMax,
            length,
            width,
            type: newTableType,
            position: {x: 100, y: 100},
            is_venue_template: true,
            surface_color: surfaceColor,
            text_color: textColor,
            seat_color: seatColor,
        }))
        setNewTableName('');
        setNewTableMax(8);
        onClose()
    }, [dispatch, newTableName, newTableMax, room_id, venue_id, newTableLength, newTableWidth, newTableType, onClose, newTableRadius])


    return (<div className='create-new-table-template'>
        <h2>Nyt bord</h2>
        <InputField title='Navn' value={newTableName} onChanged={setNewTableName}/>
        <InputField title='Antal pladser' value={newTableMax} onChanged={setNewTableMax}/>
        <InputField title='Type' options={['Square', 'Round', 'Cinema']} onlySelectableOptions value={newTableType}
                    onChanged={setNewTableType}/>

        {newTableType === 'Round' && <>
            <InputField title='Bordets radius (cm)' value={newTableRadius} onChanged={setNewTableRadius}/>
            <InputField title='Højde (cm)' value={newTableHeight} onChanged={setNewTableHeight}/>
        </>}

        {newTableType === 'Square' && <>
            <InputField title='Bordets længde (cm)' value={newTableLength} onChanged={setNewTableLength}/>
            <InputField title='Bordet bredde (cm)' value={newTableWidth} onChanged={setNewTableWidth}/>
            <InputField title='Højde (cm)' value={newTableHeight} onChanged={setNewTableHeight}/>
        </>}

        {newTableType === 'Cinema' && <>
            <InputField title='Sædde bredde' value={seatWidth} onChanged={setSeatWidth}/>
        </>}

        <TableColorPickers
            table_type={newTableType}
            surfaceColor={surfaceColor}
            onSelectedSurfaceColor={setSurfaceColor}
            textColor={textColor}
            onSelectedTextColor={setTextColor}
            seatColor={seatColor}
            onSelectedSeatColor={setSeatColor}
        />

        <SimpleButton className='create-btn' value='Opret' onClick={onCreateTable}/>
    </div>)
}
