import React from "react";
import './range-input.css';

export default function RangeInput({
                                       label,
                                       value,
                                       valueType,
                                       onChange,
                                       min,
                                       max,
                                       subDivisionStep,
                                       rangeStep = 1,
                                   }) {
    return (
        <div className='range-input'>
            <label className='title-label'>{label}</label>
            <div className='rotation-wrapper'>
                <input type='range' min={min} max={max} step={rangeStep}
                       value={Math.floor(value * subDivisionStep)}
                       onChange={v => onChange(v.target.value / subDivisionStep)}/>
                <label className='curr-value'>{Math.floor(value * subDivisionStep)} {valueType}</label>
            </div>
        </div>
    )
}
