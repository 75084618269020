import React, {useCallback, useEffect, useMemo, useState} from "react";
import MouseEventHandler from "../mouse-event-handler/MouseEventHandler";
import './dragable.css'
import {Vector} from "vector2d";
import {useDispatch, useSelector} from "react-redux";
import {selectZoom} from "../../slice/roomSlice";
import {
    doSetDraggingTable, doSetSelectedTablesAndElements,
    doSetStopDraggingTable,
    selectDraggingTableId
} from "../../slice/tableSlice";

export default function Dragable({
                                     is_active,
                                     pointer_events = true,
                                     position,
                                     rotation,
                                     style,
                                     setPosition,
                                     className,
                                     children,
                                     element_id,
                                     onAltDrag,
                                     is_table = false,
                                     ...props
                                 }) {
    const [dragging, setDragging] = useState(null);

    const zoom = useSelector(selectZoom)
    const dispatch = useDispatch();

    const draggingTableId = useSelector(selectDraggingTableId);

    const proxySetPosition = useCallback((e) => {
        if (draggingTableId !== element_id) {
            console.log('Not the curr dragging table')
            return;
        }

        const clicked = new Vector(e.movementX / zoom, e.movementY / zoom)
        const newpos = new Vector(position.x, position.y).add(clicked)
        setPosition(newpos)
    }, [zoom, setPosition, position, draggingTableId, element_id]);

    useEffect(() => {
        if (dragging === null) return;

        if (dragging) {
            dispatch(doSetDraggingTable(element_id))
        } else {
            dispatch(doSetStopDraggingTable(element_id))
        }
    }, [dragging, element_id, dispatch]);

    return <>
        <div {...props}
             draggable={is_active}
             onMouseDown={e => {
                 if (!is_active) return;
                 if (e.buttons !== 1) return;
                 if (e.altKey && onAltDrag) {
                     return;
                 }
                 e.preventDefault();
                 e.stopPropagation();
                 if (is_table) {
                     dispatch(doSetSelectedTablesAndElements({table_ids: [element_id], element_ids: []}))
                 } else {
                     setDragging(true)
                 }
             }}
             onDragStart={e => {
                 if (e.altKey && onAltDrag && is_table) {
                     onAltDrag(e)
                 }
             }}
             onMouseUp={e => {
                 if (!is_active) return;
                 if (e.buttons !== 1) return;
                 e.preventDefault();
                 e.stopPropagation();
                 setDragging(false)
             }}
             style={{
                 ...style,
                 position: 'absolute',
                 left: `${Math.floor(position.x)}px`,
                 top: `${Math.floor(position.y)}px`,
                 transform: `translateX(-50%) translateY(-50%) rotate(${rotation}deg)`,
                 pointerEvents: pointer_events ? 'all' : 'none',
             }} className={`dragable-handle ${is_active ? 'active' : ''} ${className}`}>
            {children}
        </div>
        {dragging && is_active && <MouseEventHandler
            killAllOtherEvents
            mouseMove={e => {
                e.preventDefault();
                e.stopPropagation();
                proxySetPosition(e)
            }}
            onMouseLeave={e => {
                e.preventDefault();
                e.stopPropagation();
                setDragging(false);
            }}
            mouseUp={_ => {
                setDragging(false);
            }}/>}
    </>
}
