import React, {useCallback, useEffect, useMemo, useState} from "react";
import './event-schedule-page.css';
import plus from '../../../assets/plus.svg';
import {ItineraryApi, SharedMessageApi} from "../../../api";
import {useParams} from "react-router-dom";
import Sektion from "./sektion";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import SharedMessage from "../../../component/shared-message/SharedMessage";
import InputField from "../../../component/input-field/InputField";

export default function EventSchedulePage() {
    let {event_id, venue_id} = useParams();

    const [program, setProgram] = useState(null);
    const [sektioner, setSektioner] = useState(null);
    const [tilvalg, setTilvalg] = useState([]);

    const addProgramItem = useCallback(() => {
        ItineraryApi.create_item(event_id, '', '', '').then(res => {
            setProgram(prev => ([...prev, res]));
        });
    }, [program, setProgram]);

    const addSektion = useCallback(() => {
        if (!sektioner) return;

        const section_name = `${event_id}_section-${sektioner.length + 1}`;
        SharedMessageApi.create_or_update(section_name, event_id, venue_id, ';;').then(res => {
            setSektioner(prev => ([...prev, res]));
        });
    }, [setSektioner, sektioner, event_id, venue_id]);

    useEffect(() => {
        SharedMessageApi.search_message(`${event_id}_section-`, event_id).then(res => {
            setSektioner(res);
        });
        ItineraryApi.list_items_by_event_id(event_id).then(res => {
            setProgram(res);
        });
    }, [event_id]);

    const addTilvalg = useCallback(() => {
        setTilvalg(prev => ([...prev, {id: prev.length + 1, text: '', sektioner: []}]))
    }, [setTilvalg]);

    const programIsDirty = useMemo(() => {
        if (!program) return false;
        return program.some(item => item.dirty);
    }, [program]);

    const saveProgram = useCallback(() => {
        if (!program) return;
        Promise.all(program.filter(i => i.dirty).map(item => {
            return ItineraryApi.update_item(item.id, item.from, item.to, item.text);
        })).then(() => {
            setProgram(prev => prev.map(p => ({...p, dirty: false})));
        }).catch(err => {
            console.error("Error saving program", err);
            // Optionally, handle error display to the user
        });
    }, [program]);

    if (!sektioner) return <div>Loading...</div>;

    return (<div className='event-schedule-page'>
            <section>
                <SharedMessage event_id={event_id} venue_id={venue_id}
                               message_id={`${event_id}_itinerary_generelt`}
                               message_title='Generelle noter'
                               message_description={'Generel information om arrangementet'}/>
            </section>
            <section className='program-wrapper'>
                <h4>Tidsplan</h4>
                <div className='horizontal'>
                    <p>
                        Vælg tidspunkt og beskriv tidsplanen til programmet.
                    </p>
                    <div className='item-list'>
                        {program !== null && program.map((item, index) => <div key={item.id} className='program-item'>
                            <div className='item-time-selection-wrapper'>
                                <span>fra</span>
                                <InputField type="time" value={item.from} onChanged={value => setProgram(prev => {
                                    return prev.map(p => p.id === item.id ? {
                                        ...p,
                                        from: value,
                                        dirty: true
                                    } : p)
                                })}/>
                                <span>til</span>
                                <InputField type="time" value={item.to} onChanged={value => setProgram(prev => {
                                    return prev.map(p => p.id === item.id ? {...p, to: value, dirty: true} : p)
                                })}/>
                            </div>
                            <input
                                className='program-item-text'
                                type='text'
                                value={item.text}
                                onChange={e => {
                                    const value = e.target.value;
                                    setProgram(prev => {
                                        const copy = [...prev];
                                        copy[index].text = value;
                                        copy[index].dirty = true;
                                        return copy;
                                    })
                                }}/>
                        </div>)}
                        <div className='add-program-item' onClick={_ => addProgramItem()}>
                            <span>+</span>
                        </div>
                    </div>
                </div>
                <SimpleButton value='save' onClick={saveProgram} disabled={!programIsDirty}/>
                {/* Added Save button */}
            </section>

            {false && <section>
                <h2>Tilvalg</h2>
                <table>
                    <thead>
                    <tr>
                        <th>Tekst</th>
                        {sektioner.map(sektion => <th key={sektion.id}>{sektion.name}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {tilvalg.map(tilvalg => <tr key={tilvalg.id}>
                        <td><input type='text' value={tilvalg.text} onChange={e => {
                            const value = e.target.value;
                            setTilvalg(prev => {
                                return prev.map(p => p.id === tilvalg.id ? {...p, text: value} : p)
                            })
                        }}/></td>
                        {sektioner.map(sektion => <td key={sektion.id}>
                            <input type='checkbox' checked={tilvalg.sektioner.includes(sektion.id)} onChange={e => {
                                const value = e.target.checked;
                                setTilvalg(prev => {
                                    return prev.map(p => p.id === tilvalg.id ? {
                                        ...p,
                                        sektioner: value ? [...p.sektioner, sektion.id] : p.sektioner.filter(s => s !== sektion.id)
                                    } : p)
                                })
                            }}/>
                        </td>)}
                    </tr>)}
                    <tr>
                        <td colSpan={2}>
                            <button onClick={addTilvalg}>Tilføj</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </section>}

            <section className='sections'>
                <h2>Sektioner</h2>
                <p>
                    Tekst i sektioner bliver automatisk gemt når du skriver i feltet.
                </p>
                {sektioner.map(sektion => <div key={sektion.id} className='sektion'>
                    <Sektion message_id={sektion.message_identifier} event_id={event_id} venue_id={venue_id}/>
                    <div className='horizontal'>
                        <div className='grower'>&nbsp;</div>
                        <SimpleButton red value='Slet' onClick={() => {
                            SharedMessageApi.delete_message(sektion.message_identifier, event_id).then(() => {
                                setSektioner(prev => prev.filter(s => s.id !== sektion.id));
                            });
                        }}/>
                    </div>
                </div>)}
                <button onClick={addSektion}>Tilføj sektion</button>
            </section>
        </div>
    )
}
