import React from "react";
import './color-picker.css';

export default function ColorPicker({availableColors, value, onSelectColor, label}) {
    return (<div className='color-picker'>
        <label>{label}</label>
        <div className='colors-wrapper'>
            {availableColors.map((color, idx) => <div
                key={idx} className={`color-picker-color ${value === color ? 'selected' : ''}`}
                style={{backgroundColor: color}}
                onClick={() => onSelectColor(color)}/>)}
        </div>
    </div>)
}
