import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import './tab-styles.css';
import {VenueAPI} from "../../../api";

export default function CalendarSetupTab() {
    const [calendarToken, setCalendarToken] = useState(null);
    const [copied, setCopied] = useState('');

    const {event_id, venue_id} = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        VenueAPI.get_calendar_token(venue_id).then(setCalendarToken)
    }, [venue_id]);

    const calendarUrl = useMemo(() => {
        return `https://seating-backend-development-534112410682.europe-west1.run.app/calendar/subscription/${venue_id}?calendar_token=${calendarToken}`
    }, [calendarToken, venue_id]);

    const copyToClipboard = useCallback(() => {
        if (calendarUrl === null) return;

        navigator.clipboard.writeText(calendarUrl).then(() => {
            setCopied(calendarUrl)
        }, () => {
            setCopied('')
        });
    }, [calendarUrl]);

    return (<section className='venue-settings tab'>
        <h2>Opsæt kalender</h2>
        <p></p>
        <main>
            <div>
                <p>
                    Når du abonnerer på denne kalender, modtager du automatisk events på din telefon eller computer.
                    Synkroniseringen er én-vejs fra Openseat til din enhed – du kan ikke ændre events direkte på din
                    telefon. Ændringer skal foretages via Openseat.
                </p>
                <p>
                    Vær opmærksom på, at din enhed selv tjekker for nye opdateringer. Derfor vises ændringer fra
                    Openseat
                    ikke øjeblikkeligt; det kan tage fra et par minutter til flere dage, afhængigt af dine
                    kalenderindstillinger. For eksempel kan du i iCloud indstille den til at hente opdateringer
                    hver 5. minut.
                </p>
                <p>
                    Denne type kalender er en <strong>abonnementskalender</strong>. Det betyder, at du kan tilføje
                    kalenderen til din enhed ved at bruge en url som er unik for dig. Tryk på knappen nedenfor for at
                    kopiere linket så du kan bruge den til opsætning.
                </p>
            </div>
            <div className='copy-text-wrapper'>
                <button onClick={copyToClipboard}>Kopier link til kalender</button>
                {copied && copied === calendarUrl && <span>Kopieret!</span>}
            </div>
            <details>
                <summary>Apple Kalender (iCloud)</summary>
                <ol>
                    <li>Åbn <strong>Kalender</strong>-appen på din Mac.</li>
                    <li>Klik på <strong>Arkiv</strong> &gt; <strong>Ny kalenderabonnement...</strong></li>
                    <li>Indsæt det kopierede link i dialogboksen.</li>
                    <li>Klik på <strong>Abonner</strong>.</li>
                    <li>Tilpas indstillingerne (navn, automatisk opdatering) efter behov.</li>
                    <li>Klik på <strong>OK</strong> for at tilføje kalenderen til din iCloud-konto.</li>
                </ol>
            </details>

            <details>
                <summary>Outlook</summary>
                <ol>
                    <li>Åbn <strong>Outlook</strong> på din computer.</li>
                    <li>Gå til <strong>Kalender</strong>-visningen.</li>
                    <li>Klik på <strong>Tilføj kalender</strong> &gt; <strong>Fra internettet...</strong></li>
                    <li>Indsæt det kopierede link i dialogboksen.</li>
                    <li>Klik på <strong>OK</strong>.</li>
                    <li>Når du bliver spurgt, om du vil tilføje internetkalenderen og abonnere på opdateringer,
                        klik <strong>Ja</strong>.
                    </li>
                </ol>
            </details>
            <details>
                <summary>iPhone</summary>
                <ol>
                    <li>Åbn <strong>Indstillinger</strong> på din iPhone.</li>
                    <li>Rul ned og vælg <strong>Kalender</strong>.</li>
                    <li>Tryk på <strong>Tilføj konto</strong>.</li>
                    <li>Vælg <strong>Andet</strong>.</li>
                    <li>Under <strong>Kalendere</strong>, vælg <strong>Tilføj abonnementskalender</strong>.</li>
                    <li>Indtast link (som du kan kopiere oven over), i feltet <em>Server</em>:</li>
                    <li>Tryk på <strong>Næste</strong>.</li>
                    <li>Tilpas eventuelle indstillinger og tryk på <strong>Arkiver</strong>.</li>
                </ol>
            </details>

            <details>
                <summary>Android</summary>
                <p>Processen kan variere afhængigt af din kalenderapp. Følg disse generelle trin for Google
                    Kalender:</p>
                <ol>
                    <li>Åbn en webbrowser på din telefon eller computer.</li>
                    <li>Gå til <a href="https://calendar.google.com/">Google Kalender</a> og log ind.</li>
                    <li>Klik på <strong>+</strong> ved siden af "Andre kalendere" i venstre side.</li>
                    <li>Vælg <strong>Fra webadresse</strong>.</li>
                    <li>Indsæt link (som du kan kopiere oven over) i feltet:</li>
                    <li>Klik på <strong>Tilføj kalender</strong>.</li>
                    <li>Kalenderen vil nu synkronisere med din Google Kalender-app på din Android-enhed.</li>
                </ol>
            </details>

            <h4>Ellers så giv os et kald, så hjælper vi gerne med at få det op at køre for dig :-)</h4>
        </main>
    </section>)
}
