import React, {useMemo} from "react";
import ColorPicker from "../color-picker/ColorPicker";

export default function TableColorPickers({
                                              table_type,
                                              surfaceColor,
                                              onSelectedSurfaceColor,
                                              textColor,
                                              onSelectedTextColor,
                                              seatColor,
                                              onSelectedSeatColor,
                                          }) {
    const {surfaceColors, textColors, seatColors} = useMemo(() => {
        return {
            textColors: [
                '#333333',
                '#FFFFFF',
                '#E0E0E0',
                '#D0E4F5',
                '#DDE8D9',
                '#F5E1DA',
                '#E8E0F0',
            ],
            surfaceColors: [
                '#333333',
                '#000000',
                '#1A237E',
                '#2E7D32',
                '#5D4037',
                '#4A148C',
            ],
            seatColors: [
                '#0059D4'
            ]
        }
    }, []);

    return (<div className='table-color-pickers'>
        {table_type && table_type !== 'Cinema' && <ColorPicker
            label={'Bordfarve'}
            value={surfaceColor}
            availableColors={surfaceColors}
            onSelectColor={n => onSelectedSurfaceColor(n)}
        />}
        {table_type && table_type === 'Cinema' && <ColorPicker
            label={'Baggrundsfarve'}
            value={surfaceColor}
            availableColors={surfaceColors}
            onSelectColor={n => onSelectedSurfaceColor(n+'88')}
        />}
        <ColorPicker
            label={'Tekstfarve'}
            value={textColor}
            availableColors={textColors}
            onSelectColor={n => onSelectedTextColor(n)}
        />
        <ColorPicker
            label={'Seatfarve'}
            value={seatColor}
            availableColors={seatColors}
            onSelectColor={n => onSelectedSeatColor(n)}
        />
    </div>)
}
