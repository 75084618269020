import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {VenueAPI} from "../api";

const initialState = {
    venues: null,
    loading: false,
    venueCreated: false,
    creatingRoomLoading: false,
    creatingRoomError: false,
}

export const startCreateNewVenue = createAsyncThunk('venue/creatingNew', async () => {
    return
})

export const doFetchVenueById = createAsyncThunk('venue/fetchSingle', async venue_id => {
    return await VenueAPI.get_venue_by_id(venue_id)
})

export const doFetchVenues = createAsyncThunk('venue/fetch', async () => {
    return await VenueAPI.list_all_venues()
})
export const doCreateVenue = createAsyncThunk('venue/create', async ({name, address}) => {
    return await VenueAPI.create(name, address)
})


const venueSlice = createSlice({
    name: 'venue',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(startCreateNewVenue.fulfilled, (state, action) => {
                state.loading = false
                state.venueCreated = false
            })

            .addCase(doFetchVenueById.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doFetchVenueById.fulfilled, (state, action) => {
                const fetchedVenue = action.payload
                if (state.venues.find(v => v.id === fetchedVenue.id)) {
                    state.venues = state.venues.map(v => v.id === fetchedVenue.id ? fetchedVenue : v)
                } else {
                    state.venues = [...state.venues, action.payload]
                }
                state.loading = false
            })

            .addCase(doFetchVenues.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doFetchVenues.fulfilled, (state, action) => {
                state.venues = action.payload
                state.loading = false
            })

            .addCase(doCreateVenue.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doCreateVenue.fulfilled, (state, action) => {
                state.venues = [...state.venues, action.payload]
                state.loading = false
                state.venueCreated = true
            })

    }
})

export default venueSlice.reducer

export const selectVenues = state => {
    return state.venue.venues
}

export const selectVenueLoading = state => {
    return state.venue.loading
}

export const selectVenueCreated = state => {
    return state.venue.venueCreated
}

export const selectVenueById = venue_id => state => {
    return state.venue.venues.find(v => v.id === venue_id)
}
export const createRoomLoading = state => {
    return state.venue.creatingRoomLoading
}
export const createRoomError = state => {
    return state.venue.creatingRoomError
}
