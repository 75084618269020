import React from 'react'
import {Link, useNavigate} from "react-router-dom";
import './room-list.css'
import SimpleButton from "../simple-button/SimpleButton";
import {doDeleteEventRoom} from "../../slice/roomSlice";
import {useDispatch} from "react-redux";


export default function RoomList({rooms, event_id, venue_id, seating_link = false, show_delete_btn=false}) {
    const dispatch = useDispatch();

    return (<div className='room-list'>
        {rooms.map(room => <div key={room.id} className='room-list-item'>
            <img src={room.signed} alt={`preview of ${room.name}`}/>
            <div className='details'>
                <h3>{room.name}</h3>
                <p>{room.capacity}</p>
                <ul>
                    <li><Link to={`/app/room/${room.id}`}>Bordopsætning</Link></li>
                    {seating_link &&
                        <li><Link to={`/app/venue/${venue_id}/event/${event_id}/room/${room.id}/seating`}>Seating</Link>
                        </li>}
                </ul>
            </div>
            {show_delete_btn && <SimpleButton red value='Slet lokale' onClick={_ => {
                if (window.confirm('Vil du slette dette lokale?')) {
                    dispatch(doDeleteEventRoom({event_id, room_id: room.id}))
                }
            }}/>}
        </div>)}
    </div>)
}
