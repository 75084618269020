import React, {useMemo} from 'react';

import './element-menu.css';
import BottomPagePopoutMenu from "../../../bottom-page-popout/BottomPagePopout";
import {elementTypes} from "../useElements";

export default function ElementMenu() {
    return (
        <div className='element-menu'>
            {Object.keys(elementTypes).filter(key => key === 'line').map((key, index) => {
                return <div key={index} draggable={true}
                            className='element-menu-wrapper'
                            onDragStart={e => {
                                e.dataTransfer.setData('creatingNewElement', JSON.stringify(elementTypes[key]))
                            }}>
                    {key}
                </div>
            })}
        </div>
    )
}
