import React, {useCallback, useEffect, useMemo, useState} from 'react';
import debounce from "lodash.debounce";
import {SharedMessageApi} from "../../../api";

export default function Sektion({message_id, event_id, venue_id}) {
    const [localMessage, setLocalMessage] = useState({message: ';;'});

    useEffect(() => {
        SharedMessageApi.list_message(message_id, event_id, venue_id).then(res => {
            if (res !== null) setLocalMessage(res);
        });
    }, []);

    const updateMessageBackend = useCallback((updated_msg) => {
        SharedMessageApi.create_or_update(message_id, event_id, venue_id, updated_msg)
    }, [message_id, event_id, venue_id]);

    const _updateMessage = useMemo(() => debounce(updateMessageBackend, 800), [updateMessageBackend]);

    const setMessage = useCallback((msg) => {
        _updateMessage(msg)
        setLocalMessage(prev => ({...prev, message: msg}))
    }, [_updateMessage]);

    const {title, text} = useMemo(() => {
        const [title, text] = localMessage.message.split(';;');

        return {title, text};
    }, [localMessage.message]);

    return (<div className='shared-message'>
        <input type='text' placeholder='Navn' value={title} onChange={e => {
            const value = e.target.value;
            setMessage(`${value};;${text}`)
        }}/>
        <textarea placeholder='Tekst' value={text} onChange={e => {
            const value = e.target.value;
            setMessage(`${title};;${value}`)
        }}/>
    </div>)
}
