import React, {useCallback, useEffect, useState} from 'react';

import './selected-tables-rect-movable.css';
import {useDispatch, useSelector} from "react-redux";
import {
    doSetDraggingTable, doSetStopDraggingTable, selectSelectedElements,
    selectSelectedTableRect,
    selectSelectedTables
} from "../../../slice/tableSlice";
import {selectZoom} from "../../../slice/roomSlice";
import MouseEventHandler from "../../mouse-event-handler/MouseEventHandler";
import {Vector} from "vector2d";
import useSetTablePosition from "../useSetTablePosition";
import useElementBackend from "../element/useElementBackend";
import {doSetContextMenu, doSetElementPropertyLocally} from "../../../slice/elementSlice";

export default function SelectedTablesRectMovable() {
    const [dragging, setDragging] = useState(false);

    const dispatch = useDispatch();
    const selectedTablesRect = useSelector(selectSelectedTableRect);

    const selectedTables = useSelector(selectSelectedTables);
    const selectedElements = useSelector(selectSelectedElements);

    const zoom = useSelector(selectZoom)

    const {setLocally, updateBackend, setMultipleLocally} = useSetTablePosition();
    const {updateElementBackend} = useElementBackend();

    const moveAllSelectedTables = useCallback(e => {
        if (e.buttons !== 1) {
            setDragging(false);
            dispatch(doSetStopDraggingTable('multiple'))
            return;
        }
        const movement = new Vector(e.movementX / zoom, e.movementY / zoom)

        setMultipleLocally(selectedTables.map(t => ({
            id: t.id,
            position: {
                x: t.position.x + movement.x,
                y: t.position.y + movement.y
            }
        })))
        updateBackend()

        selectedElements.forEach(e => {
            if (e.properties.position) {
                dispatch(doSetElementPropertyLocally({
                    element_id: e.id,
                    key: 'position',
                    value: {
                        x: e.properties.position.x + movement.x,
                        y: e.properties.position.y + movement.y,
                    }
                }))
            }
            if (e.properties.position_end) {
                dispatch(doSetElementPropertyLocally({
                    element_id: e.id,
                    key: 'position_end',
                    value: {
                        x: e.properties.position_end.x + movement.x,
                        y: e.properties.position_end.y + movement.y,
                    }
                }))
            }
            if (e.properties.position_start) {
                dispatch(doSetElementPropertyLocally({
                    element_id: e.id,
                    key: 'position_start',
                    value: {
                        x: e.properties.position_start.x + movement.x,
                        y: e.properties.position_start.y + movement.y,
                    }
                }))
            }
        })
        updateElementBackend()

    }, [dispatch, selectedTables, selectedElements, zoom, setLocally, updateElementBackend]);

    const startDrag = useCallback(() => {
        setDragging(true);
        dispatch(doSetDraggingTable('multiple'))
    }, [dispatch]);

    const stopDrag = useCallback(() => {
        setDragging(false);
        dispatch(doSetStopDraggingTable('multiple'))
    }, []);

    if (selectedTablesRect === null) return null;

    return <div className={`selected-rect-movable ${dragging ? 'dragging' : ''}`}
                draggable={true}
                onMouseDown={e => {
                    if (e.buttons !== 1) return;
                    if (e.altKey) {
                        return;
                    }
                    e.preventDefault();
                    e.stopPropagation();
                    startDrag()
                }}
                onMouseMove={e => {
                    if (e.buttons !== 1) return;
                    if (e.altKey) return;
                    if (dragging) return;
                    setDragging(true)
                    moveAllSelectedTables(e)
                }}
                onDragStart={e => {
                    console.log('dragging from selected rect')
                    e.dataTransfer.setData('application/tableDragCreate', JSON.stringify(selectedTables))
                }}
                onMouseUp={e => {
                    if (e.buttons !== 1) return;
                    e.preventDefault();
                    e.stopPropagation();
                    stopDrag();
                }}
                onContextMenu={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(doSetContextMenu({
                        element_ids: selectedElements.map(e => e.id),
                        table_ids: selectedTables.map(t => t.id),
                    }))
                }}
                style={{
                    left: `${selectedTablesRect.left}px`,
                    top: `${selectedTablesRect.top}px`,
                    width: `${selectedTablesRect.width}px`,
                    height: `${selectedTablesRect.height}px`,
                }}>
        {dragging && <MouseEventHandler killAllOtherEvents
                                        mouseUp={stopDrag}
                                        mouseMove={e => {
                                            moveAllSelectedTables(e)
                                        }}
        />}
    </div>
}
