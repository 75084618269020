import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {EventApi, RoomAPI, VenueAPI} from "../api";
import {Vector} from "vector2d";

const initialState = {
    rooms: {},
    event_rooms: {},
    venue_rooms: {},
    loading: false,
    zoom: 1,
    naturalImageSize: null,
    clientImageSize: null,
    active_room_id: null,
}

export const doFetchRoom = createAsyncThunk('room/fetch', async (room_id) => {
    return await RoomAPI.get_room_by_id(room_id)
})

export const doFetchEventRoom = createAsyncThunk('room/doFetchEventRoom', async (room_id) => {
    return await EventApi.get_event_room(room_id)
})

export const doFetchVenueRooms = createAsyncThunk('venue/fetchSingleRooms', async venue_id => {
    return new Promise(res => VenueAPI.list_venue_rooms(venue_id).then(rooms => {
        res({rooms, venue_id})
    }))
})
export const doCreateVenueRoom = createAsyncThunk('venue/createRoom', async (args) => {
    const newRoom = await RoomAPI.create(args)
    return {newRoom, venue_id: args.venue_id}
})
export const doDeleteVenueRoom = createAsyncThunk('venue/doDeleteVenueRoom', async ({room_id, venue_id}) => {
    return RoomAPI.delete_room(room_id).then(_ => ({room_id, venue_id}))
})

export const doDeleteEventRoom = createAsyncThunk('event/doDeleteEventRoom', async ({room_id, event_id}) => {
    return RoomAPI.delete_room(room_id).then(_ => ({room_id, event_id}))
})


export const doFetchEventRooms = createAsyncThunk('event/doFetchEventsRooms', async event_id => {
    return EventApi.list_rooms_by_event_id(event_id).then(rooms => {
        return {
            event_id,
            rooms
        }
    })
})

export const doUpdateRoom = createAsyncThunk('room/update', async ({room_id, values}) => {
    return await RoomAPI.update_room(room_id, values)
})

export const doCreateRoom = createAsyncThunk('room/create', async ({venue_id, name, width, height}) => {
    return await RoomAPI.create(
        venue_id,
        name,
        0,
        0,
        width,
        height,
    )
})

export const doSetZoom = createAsyncThunk('room/setZoom', async (zoom) => {
    return new Promise(res => res(zoom))
})
export const setExternalNaturalImageSize = createAsyncThunk('room/setNaturalImageSize', async (size) => {
    return size
})
export const setExternalClientImageSize = createAsyncThunk('room/setClientImageSize', async (size) => {
    return size
})

export const doSetActiveRoomId = createAsyncThunk('room/setActiveRoomId', async (room_id) => {
    return room_id
})


const roomSlice = createSlice({
    name: 'room',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(doFetchRoom.fulfilled, (state, action) => {
                const fetchedRoom = action.payload
                state.rooms[fetchedRoom.id] = fetchedRoom
            })

            .addCase(doFetchEventRooms.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doFetchEventRooms.fulfilled, (state, action) => {
                if (!action.payload) return;
                const {event_id, rooms} = action.payload
                state.event_rooms[event_id] = rooms
                rooms.forEach(room => {
                    state.rooms[room.id] = room
                    state.active_room_id = room.id
                    state.loading = false
                })
            })

            .addCase(doFetchVenueRooms.fulfilled, (state, action) => {
                const {rooms, venue_id} = action.payload
                state.venue_rooms[venue_id] = rooms
            })
            .addCase(doCreateVenueRoom.fulfilled, (state, action) => {
                const {venue_id, newRoom} = action.payload
                console.log('Created new room:', action.payload)
                const existing = state.venue_rooms[venue_id] || []
                state.venue_rooms[venue_id] = [...existing, newRoom]
                state.creatingRoomLoading = false
            })
            .addCase(doCreateVenueRoom.pending, (state, action) => {
                state.creatingRoomLoading = true
            })

            .addCase(doCreateVenueRoom.rejected, (state, action) => {
                state.creatingRoomLoading = false
                state.creatingRoomError = action.payload
            })
            .addCase(doDeleteVenueRoom.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doDeleteVenueRoom.fulfilled, (state, action) => {
                const {room_id, venue_id} = action.payload
                state.venue_rooms[venue_id] = state.venue_rooms[venue_id].filter(r => r.id !== room_id)
                state.loading = false
            })

            .addCase(doDeleteEventRoom.fulfilled, (state, action) => {
                const {room_id, event_id} = action.payload
                console.log('Deleted event room', {
                    state,
                    room_id,
                    event_id
                })
                state.event_rooms[event_id] = state.event_rooms[event_id].filter(r => r.id !== room_id)
            })

            .addCase(doUpdateRoom.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doUpdateRoom.fulfilled, (state, action) => {
                const updated = action.payload
                state.rooms[updated.id] = updated
                state.loading = false
                state.active_room_id = updated.id
            })

            .addCase(doCreateRoom.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doCreateRoom.fulfilled, (state, action) => {
                const newRoom = action.payload
                state.rooms[newRoom.id] = newRoom
                state.loading = false
            })

            .addCase(doSetZoom.fulfilled, (state, action) => {
                state.zoom = action.payload
            })
            .addCase(setExternalNaturalImageSize.fulfilled, (state, action) => {
                state.naturalImageSize = action.payload
            })
            .addCase(setExternalClientImageSize.fulfilled, (state, action) => {
                state.clientImageSize = action.payload
            })

            .addCase(doSetActiveRoomId.fulfilled, (state, action) => {
                state.active_room_id = action.payload
            })
    }
})

export default roomSlice.reducer

export const selectRoomById = passed_id => createSelector([state => state.room.active_room_id, state => state.room.rooms], (room_id, rooms) => {
    if (passed_id) {
        return rooms[passed_id] || null
    }
    return rooms[room_id] || null
})
export const selectActiveRoom = createSelector([state => state.room.active_room_id, state => state.room.rooms], (room_id, rooms) => {
    return rooms[room_id] || null
})

export const selectZoom = state => {
    return state.room.zoom
}
export const selectEventRooms = event_id => state => {
    return state.room.event_rooms[event_id] || []
}

export const selectVenueRooms = venue_id => state => {
    return state.room.venue_rooms[parseInt(venue_id)] || []
}

export const selectNaturalImageSize = state => {
    const v = state.room.naturalImageSize
    if (!v) return null
    return new Vector(v.x, v.y)
}
export const selectClientImageSize = state => {
    const v = state.room.clientImageSize
    return new Vector(v.x, v.y)
}
