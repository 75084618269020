import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import InputField from "../../../component/input-field/InputField";

import {
    doDeleteTable,
    doSetEditingTable,
    doSetTableLocally,
    selectSelectedTableRect, selectSelectedTables, thunkSaveDirtyTables
} from "../../../slice/tableSlice";
import debounce from "lodash.debounce";
import './table-edit-window.css';
import {doSetContextMenu} from "../../../slice/elementSlice";
import binImg from '../../../assets/bin.png';
import ColorPicker from "../../../component/color-picker/ColorPicker";
import RangeInput from "../../../component/range-input/RangeInput";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import TableColorPickers from "../../../component/table-color-pickers/TableColorPickers";


export default function TableEditWindow({
                                            editingVenue,
                                            editingEvent,
                                            showWidth = true,
                                            showLength = true,
                                        }) {
    const [changed, setChanged] = useState({});

    const dispatch = useDispatch()

    const editingTables = useSelector(selectSelectedTables);
    const selectedTablesRect = useSelector(selectSelectedTableRect);

    const zoom = useSelector(state => state.room.zoom);

    useEffect(() => {
        setChanged({})
    }, [editingTables]);

    const deleteSelectedTables = useCallback(() => {
        if (window.confirm(`Er du sikker på at du vil slette ${editingTables.length} bord${editingTables.length !== 1 ? 'e' : ''}?`)) {
            dispatch(doSetContextMenu(null));
            editingTables.forEach(t => dispatch(doDeleteTable(t.id)));
        }
    }, [editingTables, dispatch]);

    const updateTableBackend = useCallback((changes) => {
        dispatch(thunkSaveDirtyTables)
    }, [dispatch]);

    const debouncedUpdateTable = useMemo(() => debounce(updateTableBackend, 800), [updateTableBackend]);

    useEffect(() => {
        if (Object.keys(changed).length === 0) return;

        debouncedUpdateTable(changed)
    }, [changed, debouncedUpdateTable]);

    const setTable = useCallback((id, values) => {
        setChanged(p => ({...p, [id]: {...p[id], ...values}}));
        dispatch(doSetTableLocally({table_id: id, values}))
    }, [debouncedUpdateTable, dispatch]);

    const closeEdit = useCallback(() => {
        dispatch(doSetEditingTable(null));
        dispatch(doSetContextMenu(null));
    }, [dispatch]);

    const similarValues = useMemo(() => {
        if (!editingTables) return {};
        const res = {
            name: null,
            type: null,
            active_seats: null,
            max_seating: null,
            width: null,
            length: null,
            rotation: null,
            seat_size: null,
            surface_color: null,
            text_color: null,
            seat_color: null,
        }
        for (const t of Object.values(editingTables)) {
            for (const key of Object.keys(res)) {
                if (res[key] === null) res[key] = t[key];
                if (res[key] !== t[key]) res[key] = '';
            }
        }
        return res
    }, [editingTables]);

    if (!editingTables || Object.keys(editingTables).length === 0 || !selectedTablesRect) return;

    return (<div className='table-edit-window'
                 title={similarValues.name}
                 onClose={closeEdit}>
        <InputField
            title='Bordnavn'
            value={similarValues.name}
            onChanged={n => editingTables.forEach(t => setTable(t.id, {name: n}))}
        />
        {editingEvent && <InputField
            title='Seats til dette event'
            value={similarValues.active_seats}
            onChanged={n => editingTables.forEach(t => setTable(t.id, {active_seats: n, max_seating: n}))}
        />}
        {editingVenue && <InputField
            title='Max antal sæder'
            value={similarValues.max_seating}
            onChanged={n => editingTables.forEach(t => setTable(t.id, {max_seating: n}))}
        />}

        {((editingEvent && similarValues.active_seats > 0) || (editingVenue && similarValues.max_seating > 0)) &&
            <RangeInput
                label='Sædde størrelse (radius)'
                value={similarValues.seat_size}
                valueType='cm'
                onChange={v => editingTables.forEach(t => setTable(t.id, {seat_size: v}))}
                min={20}
                max={70}
                subDivisionStep={100}
                rangeStep={1}
            />
        }
        {showWidth && <InputField
            title='Bredde'
            value={similarValues.width}
            onChanged={n => editingTables.forEach(t => setTable(t.id, {width: n}))}
        />}
        {showLength && <InputField
            title='Længde'
            value={similarValues.length}
            onChanged={n => editingTables.forEach(t => setTable(t.id, {length: n}))}
        />}

        <RangeInput
            label='Rotation'
            value={similarValues.rotation}
            valueType='°'
            onChange={v => editingTables.forEach(t => setTable(t.id, {rotation: v}))}
            min={-180}
            max={180}
            subDivisionStep={1}
            rangeStep={15}
        />

        <TableColorPickers
            table_type={similarValues.type}
            surfaceColor={similarValues.surface_color}
            onSelectedSurfaceColor={n => editingTables.forEach(t => setTable(t.id, {surface_color: n}))}
            textColor={similarValues.text_color}
            onSelectedTextColor={n => editingTables.forEach(t => setTable(t.id, {text_color: n}))}
            seatColor={similarValues.seat_color}
            onSelectedSeatColor={n => editingTables.forEach(t => setTable(t.id, {seat_color: n}))}
        />
        <SimpleButton onClick={deleteSelectedTables} value='Slet'/>
    </div>)
}
